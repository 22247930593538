import React, {Fragment} from "react";
import {FullWidthContainer, TheTeamAdvisorContainer} from "../../style";
import cpoemTeamBadge from "../../assets/images/cpoem-team-badge.svg";
import {teamList} from "../../configs/team.config";

const TheCPOEMTeam = () => {
  return (
    <Fragment>
      <FullWidthContainer className="cpoem-team-page">
        <img src={cpoemTeamBadge} className="logo" alt="logo"/>
        {/* {
          cpoemTeam.map(el => {
            const {pic, name, qualification, expertise, researchInterests, recognition} = el;
            return (
              <TheCPOEMTeamContainer key={name + qualification} className="grid-container">
                <div className="doctor-desc flex-container column justify-end">
                  <h3 className="name">{name}</h3>
                  <p className="qualification">{qualification}</p>
                </div>
                <div className="photo">
                  <img src={pic} alt="team leader"/>
                </div>
                <div className="expertise">
                  <div className="block-heading">Expertise</div>
                  <ul className="list">
                    {
                      expertise.map(el => <li key={el}>{el}</li>)
                    }
                  </ul>
                </div>
                <div className="research-interests">
                  <div className="block-heading">Research interests</div>
                  <ul className="list">
                    {
                      researchInterests.map(el => <li key={el}>{el}</li>)
                    }
                  </ul>
                </div>
                <div className="recognition">
                  <div className="block-heading">Recognition</div>
                  <ul className="list">
                    {
                      recognition.map(el => <li key={el}>{el}</li>)
                    }
                  </ul>
                </div>
              </TheCPOEMTeamContainer>
            )
          })
        } */}
      </FullWidthContainer>
      <TheTeamAdvisorContainer className="team-advisors section-spacing">
        <div className="advisor-list grid-container">
          {
            teamList.map(el => {
              const {pic, name, qualification, desc} = el;
              return (
                <div className="advisor" key={name + qualification}>
                  <div className="flex-container vertical-end">
                    <div className="image">
                      <img src={pic} alt="advisor"/>
                    </div>
                    <div className="flexbox name-qualification">
                      <h3 className="name">{name}</h3>
                      <div className="qualification">{qualification}</div>
                    </div>
                  </div>
                  <p className="advisor-details">{desc}</p>
                </div>
              )
            })
          }
        </div>
      </TheTeamAdvisorContainer>
    </Fragment>
  );
};

export default TheCPOEMTeam;