import React from "react";
import {TheTeamAdvisorContainer} from "../../style";
import {advisorList} from "../../configs/team.config";

const TeamAdvisors = () => {
  
  return (
    <TheTeamAdvisorContainer className="team-advisors">
      <h2 className="section-heading">Advisors</h2>
      <div className="advisor-list grid-container">
        {
          advisorList.map(el => {
            const {pic, name, qualification, desc} = el;
            return (
              <div className="advisor" key={name + qualification}>
                <div className="flex-container vertical-end">
                  <div className="image">
                    <img src={pic} alt="advisor"/>
                  </div>
                  <div className="flexbox name-qualification">
                    <h3 className="name">{name}</h3>
                    <div className="qualification">{qualification}</div>
                  </div>
                </div>
                <p className="advisor-details">{desc}</p>
              </div>
            )
          })
        }
      </div>
    </TheTeamAdvisorContainer>
  )
};

export default TeamAdvisors;