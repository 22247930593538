import React from "react";
import {GlobalStyle, MainContainer} from "../style";
import Head from "../components/common/head";
import NavBar from "../components/common/navBar";
import FooterBar from "../components/common/footer";

import TeamAdvisors from "../components/the-team/teamAdvisors";
import TheCPOEMTeam from "../components/the-team/theCpoemTeam";
import BoardOfTrustees from "../components/the-team/boardOfTrustees";

const TheTeam = () => {
  return (
    <MainContainer>
      <GlobalStyle/>
      <Head/>
      <NavBar/>
      <TheCPOEMTeam/>
      <TeamAdvisors/>
      <BoardOfTrustees/>
      <FooterBar/>
    </MainContainer>
  );
};

export default TheTeam;