import React from "react";
import {AdvisorContainer} from "../../style";

import {teamList, /* advisorList */} from "../../configs/team.config";

const BoardOfTrustees = () => {
  // const linuJacob = advisorList.find(el => el.name === "Linu Abraham Jacob");
  const trusteeList = [...teamList, /* linuJacob */];
  return (
    <AdvisorContainer className="advisor-container">
      <h2 className="section-heading">Board of Trustees</h2>
      <div className="advisors grid-container">
        {
          trusteeList.map((el, index) => {
            const {name, pic} = el;
            return (
              <div key={name + index} className="advisor text-center">
                <img src={pic} alt="advisor"/>
                <div className="name">{name}</div>
              </div>
            )
          })
        }
      </div>
    </AdvisorContainer>
  )
};

export default BoardOfTrustees;